//Colors
$primary-color: #39414C;
$secondary-color: #4CD981;

$overlay-color: rgba(darken(desaturate(adjust-hue($primary-color, 1), 3.17), 8.43),.92);

$heading-color: lighten(desaturate(adjust-hue($primary-color, 8), 9.52), 2.75);
$body-color: lighten(desaturate(adjust-hue($primary-color, -3), 7.44), 16.86);

$datepicker-color: $primary-color;

//Feedback colors
$alert-color: #e74c3c;
$success-color: #2ecc71;
$warning-color: #f1c40f;


//Fonts
$heading-font: 'Montserrat', sans-serif;
$body-font: 'Montserrat', sans-serif;

$breakpoints: (s: '0px', m: '640px', l: '1024px');

//Forms
$form-gutter: 10px;





