.CasePage {
	.intro {
		.caseIntroImage {
			margin-top: -180px; } }
	.content {
		padding: 115px 0;
		background: url('img/poly-grey.svg') no-repeat top center #f8f8f8;
		background-size: 100% auto; }
	.caseImages {
		padding: 100px 0 70px;
		.image-item {
			margin-bottom: 3%; } }
	.epilogue {
		padding: 70px 0 140px;
		.epilogue-columns {
			margin-top: 72px; }
		.statData-list {
			margin-top: 71px;
			.value {
				font-size: 35px;
				line-height: 1.2;
				font-weight: 400; }
			.label {
				font-size: 9px;
				letter-spacing: 1px;
				font-weight: bold;
				text-transform: uppercase; } } } }

.segway {
	position: relative;
	z-index: 1;
	height: 300px;
	font-size: 25px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	//background: no-repeat center -80px
	//background-size: cover
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: $overlay-color; }
	.text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 900px;
		max-width: 90%; } }
