//Animations
@keyframes fadedown {
	0% {
		opacity: 0;
		transform: translateY(-50%); }
	100% {
		opacity: 1;
		transform: translateY(0%); } }

@keyframes pop {
	0% {
		transform: scale(0); }
	90% {
		transform: scale(1.2); }
	100% {
		transform: scale(1); } }
