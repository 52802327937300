//Normalize fixes
fieldset {
	border: 0;
	margin: 0;
	padding: 0; }

html.mm-front #mm-blocker {
	z-index: 9; }
.mm-menu.mm-front {
	z-index: 10;
	background: $primary-color;
	li {
		list-style: none;
		&:before {
			content: none; } } }
//Lightgallery styling
.lg-backdrop {
	background: rgba(darken(saturate(adjust-hue($primary-color, 0.1217), 7.3184), 9.4118),.9); }
.lg-actions .lg-next, .lg-actions .lg-prev {
	background: none;
	top: 43%; }
.lg-sub-html, .lg-toolbar {
	background: transparent; }
#lg-counter {
	display: none; }
.lg-sub-html {
	margin: 0 auto 30px;
	padding: 0 20px;
	width: 800px;
	max-width: 100%; }
.lg-object {
	border: 10px solid #fff;
	box-shadow: 0 1px 5px rgba(0,0,0,.5);
	background: #ffffff; }
.lg-outer .lg-inner {
	height: calc( 100% - 100px ); }

//Fix to lightgallery icons
.lg-actions .lg-icon {
	font-family: 'FontAwesome';
	color: #ffffff;
	&.lg-prev {
		@media (min-width: map-get($breakpoints, l)) {
			left: 10vw; }
		&:after {
			content: url('img/lg-prv.svg') !important; } }
	&.lg-next {
		@media (min-width: map-get($breakpoints, l)) {
			right: 10vw; }
		&:before {
			content: url('img/lg-nxt.svg') !important; } } }
.lg-sub-html, .lg-toolbar {
	opacity: 1 !important; }
.lg-close {
	display: block;
	position: absolute;
	top: 140px;
	right: 25%;
	width: 35px !important;
	height: 35px !important;
	background: url('img/close.svg') no-repeat center center;
	background-size: 100% 100%;
	overflow: hidden;
	border-radius: 50%;
	transition: all .2s !important;
	cursor: pointer;
	&:hover {
		transform: rotate(90deg) !important; }
	&:after {
		display: none; } }
