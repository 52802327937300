.lg-sub-html, .lg-toolbar {
	background-color: rgba(0, 0, 0, 0.45); }

#lg-counter, .lg-outer .lg-video-cont {
	vertical-align: middle;
	display: inline-block; }

@font-face {
	font-family: lg;
	src: url(../fonts/lg.eot?n1z373);
	src: url(../fonts/lg.eot?#iefixn1z373) format("embedded-opentype"), url(../fonts/lg.woff?n1z373) format("woff"), url(../fonts/lg.ttf?n1z373) format("truetype"), url(../fonts/lg.svg?n1z373#lg) format("svg");
	font-weight: 400;
	font-style: normal; }


.lg-icon {
	font-family: lg;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.lg-actions {
	.lg-next, .lg-prev {
		background-color: rgba(0, 0, 0, 0.45);
		border-radius: 2px;
		color: #999;
		cursor: pointer;
		display: block;
		font-size: 22px;
		margin-top: -10px;
		padding: 8px 10px 9px;
		position: absolute;
		top: 50%;
		z-index: 1080; }
	.lg-next.disabled, .lg-prev.disabled {
		pointer-events: none;
		opacity: .5; }
	.lg-next:hover, .lg-prev:hover {
		color: #FFF; }
	.lg-next {
		right: 20px;
		&:before {
			content: "\e095"; } }
	.lg-prev {
		left: 20px;
		&:after {
			content: "\e094"; } } }

@-webkit-keyframes lg-right-end {
	0%,100% {
		left: 0; }

	50% {
		left: -30px; } }


@-moz-keyframes lg-right-end {
	0%,100% {
		left: 0; }

	50% {
		left: -30px; } }


@-ms-keyframes lg-right-end {
	0%,100% {
		left: 0; }

	50% {
		left: -30px; } }


@keyframes lg-right-end {
	0%,100% {
		left: 0; }

	50% {
		left: -30px; } }


@-webkit-keyframes lg-left-end {
	0%,100% {
		left: 0; }

	50% {
		left: 30px; } }


@-moz-keyframes lg-left-end {
	0%,100% {
		left: 0; }

	50% {
		left: 30px; } }


@-ms-keyframes lg-left-end {
	0%,100% {
		left: 0; }

	50% {
		left: 30px; } }


@keyframes lg-left-end {
	0%,100% {
		left: 0; }

	50% {
		left: 30px; } }


.lg-outer {
	&.lg-right-end .lg-object {
		-webkit-animation: lg-right-end .3s;
		-o-animation: lg-right-end .3s;
		animation: lg-right-end .3s;
		position: relative; }
	&.lg-left-end .lg-object {
		-webkit-animation: lg-left-end .3s;
		-o-animation: lg-left-end .3s;
		animation: lg-left-end .3s;
		position: relative; } }

.lg-toolbar {
	z-index: 1082;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	.lg-icon {
		color: #999;
		cursor: pointer;
		float: right;
		font-size: 24px;
		height: 47px;
		line-height: 27px;
		padding: 10px 0;
		text-align: center;
		width: 50px;
		text-decoration: none !important;
		outline: 0;
		-webkit-transition: color .2s linear;
		-o-transition: color .2s linear;
		transition: color .2s linear;
		&:hover {
			color: #FFF; } }
	.lg-close:after {
		content: "\e070"; }
	.lg-download:after {
		content: "\e0f2"; } }

.lg-sub-html {
	bottom: 0;
	color: #EEE;
	font-size: 16px;
	left: 0;
	padding: 10px 40px;
	position: fixed;
	right: 0;
	text-align: center;
	z-index: 1080;
	h4 {
		margin: 0;
		font-size: 13px;
		font-weight: 700; }
	p {
		font-size: 12px;
		margin: 5px 0 0; } }

#lg-counter {
	color: #999;
	font-size: 16px;
	padding-left: 20px;
	padding-top: 12px; }

.lg-next, .lg-prev, .lg-toolbar {
	opacity: 1;
	-webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	-moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	-o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items {
	.lg-prev {
		opacity: 0;
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0); }
	.lg-next {
		opacity: 0;
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0); }
	.lg-toolbar {
		opacity: 0;
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0); } }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom {
	.lg-object {
		-webkit-transform: scale3d(0.5, 0.5, 0.5);
		transform: scale3d(0.5, 0.5, 0.5);
		opacity: 0;
		-webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
		-moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
		-o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
		transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
		-webkit-transform-origin: 50% 50%;
		-moz-transform-origin: 50% 50%;
		-ms-transform-origin: 50% 50%;
		transform-origin: 50% 50%; }
	.lg-item.lg-complete .lg-object {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
		opacity: 1; } }

.lg-outer {
	.lg-thumb-outer {
		background-color: #0D0A0A;
		bottom: 0;
		position: absolute;
		width: 100%;
		z-index: 1080;
		max-height: 350px;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		-webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
		-moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
		-o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
		transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
		&.lg-grab .lg-thumb-item {
			cursor: -webkit-grab;
			cursor: -moz-grab;
			cursor: -o-grab;
			cursor: -ms-grab;
			cursor: grab; }
		&.lg-grabbing .lg-thumb-item {
			cursor: move;
			cursor: -webkit-grabbing;
			cursor: -moz-grabbing;
			cursor: -o-grabbing;
			cursor: -ms-grabbing;
			cursor: grabbing; }
		&.lg-dragging .lg-thumb {
			-webkit-transition-duration: 0s !important;
			transition-duration: 0s !important; } }
	&.lg-thumb-open .lg-thumb-outer {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0); }
	.lg-thumb {
		padding: 10px 0;
		height: 100%;
		margin-bottom: -5px; }
	.lg-thumb-item {
		cursor: pointer;
		float: left;
		overflow: hidden;
		height: 100%;
		border: 2px solid #FFF;
		border-radius: 4px;
		margin-bottom: 5px;
		&.active, &:hover {
			border-color: #a90707; }
		img {
			width: 100%;
			height: 100%; } }
	&.lg-has-thumb .lg-item {
		padding-bottom: 120px; }
	&.lg-can-toggle .lg-item {
		padding-bottom: 0; }
	&.lg-pull-caption-up {
		.lg-sub-html {
			-webkit-transition: bottom .25s ease;
			-o-transition: bottom .25s ease;
			transition: bottom .25s ease; }
		&.lg-thumb-open .lg-sub-html {
			bottom: 100px; } }
	.lg-toogle-thumb {
		background-color: #0D0A0A;
		border-radius: 2px 2px 0 0;
		color: #999;
		cursor: pointer;
		font-size: 24px;
		height: 39px;
		line-height: 27px;
		padding: 5px 0;
		position: absolute;
		right: 20px;
		text-align: center;
		top: -39px;
		width: 50px;
		&:after {
			content: "\e1ff"; }
		&:hover {
			color: #FFF; } }
	.lg-video-cont {
		max-width: 1140px;
		max-height: 100%;
		width: 100%;
		padding: 0 5px; }
	.lg-video {
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		overflow: hidden;
		position: relative;
		.lg-object {
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important; }
		.lg-video-play {
			width: 84px;
			height: 59px;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -42px;
			margin-top: -30px;
			z-index: 1080;
			cursor: pointer; } }
	.lg-has-vimeo {
		.lg-video-play {
			background: url(../img/vimeo-play.png) no-repeat; }
		&:hover .lg-video-play {
			background: url(../img/vimeo-play.png) 0 -58px no-repeat; } }
	.lg-has-html5 {
		.lg-video-play {
			background: url(../img/video-play.png) no-repeat;
			height: 64px;
			margin-left: -32px;
			margin-top: -32px;
			width: 64px;
			opacity: .8; }
		&:hover .lg-video-play {
			opacity: 1; } }
	.lg-has-youtube {
		.lg-video-play {
			background: url(../img/youtube-play.png) no-repeat; }
		&:hover .lg-video-play {
			background: url(../img/youtube-play.png) 0 -60px no-repeat; } }
	.lg-video-object {
		width: 100% !important;
		height: 100% !important;
		position: absolute;
		top: 0;
		left: 0; }
	.lg-has-video {
		.lg-video-object {
			visibility: hidden; }
		&.lg-video-playing {
			.lg-object, .lg-video-play {
				display: none; }
			.lg-video-object {
				visibility: visible; } } } }

@media (min-width: 1025px) {
	.lg-outer .lg-thumb-item {
		-webkit-transition: border-color .25s ease;
		-o-transition: border-color .25s ease;
		transition: border-color .25s ease; } }

.lg-progress-bar {
	background-color: #333;
	height: 5px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1080;
	opacity: 0;
	-webkit-transition: opacity 80ms ease 0s;
	-moz-transition: opacity 80ms ease 0s;
	-o-transition: opacity 80ms ease 0s;
	transition: opacity 80ms ease 0s;
	.lg-progress {
		background-color: #a90707;
		height: 5px;
		width: 0; }
	&.lg-start .lg-progress {
		width: 100%; } }

.lg-show-autoplay .lg-progress-bar {
	opacity: 1; }

.lg-autoplay-button:after {
	content: "\e01d"; }

.lg-show-autoplay .lg-autoplay-button:after {
	content: "\e01a"; }

.lg-outer {
	&.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable {
		.lg-image, .lg-img-wrap {
			-webkit-transition-duration: 0s;
			transition-duration: 0s; } }
	.lg-item.lg-complete.lg-zoomable {
		.lg-img-wrap {
			-webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
			-moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
			-o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
			transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			backface-visibility: hidden; }
		.lg-image {
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
			-webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
			-moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
			-o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
			transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
			-webkit-transform-origin: 0 0;
			-moz-transform-origin: 0 0;
			-ms-transform-origin: 0 0;
			transform-origin: 0 0;
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			backface-visibility: hidden; } } }

#lg-zoom-in:after {
	content: "\e311"; }

#lg-actual-size {
	font-size: 20px;
	&:after {
		content: "\e033"; } }

#lg-zoom-out {
	opacity: .5;
	pointer-events: none;
	&:after {
		content: "\e312"; } }

.lg-zoomed #lg-zoom-out {
	opacity: 1;
	pointer-events: auto; }

.lg-outer {
	.lg-pager-outer {
		bottom: 60px;
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		z-index: 1080;
		height: 10px;
		&.lg-pager-hover .lg-pager-cont {
			overflow: visible; } }
	.lg-pager-cont {
		cursor: pointer;
		display: inline-block;
		overflow: hidden;
		position: relative;
		vertical-align: top;
		margin: 0 5px;
		&:hover .lg-pager-thumb-cont {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0); }
		&.lg-pager-active .lg-pager {
			box-shadow: 0 0 0 2px #fff inset; } }
	.lg-pager-thumb-cont {
		background-color: #fff;
		color: #FFF;
		bottom: 100%;
		height: 83px;
		left: 0;
		margin-bottom: 20px;
		margin-left: -60px;
		opacity: 0;
		padding: 5px;
		position: absolute;
		width: 120px;
		border-radius: 3px;
		-webkit-transition: opacity .15s ease 0s,-webkit-transform .15s ease 0s;
		-moz-transition: opacity .15s ease 0s,-moz-transform .15s ease 0s;
		-o-transition: opacity .15s ease 0s,-o-transform .15s ease 0s;
		transition: opacity .15s ease 0s,transform .15s ease 0s;
		-webkit-transform: translate3d(0, 5px, 0);
		transform: translate3d(0, 5px, 0);
		img {
			width: 100%;
			height: 100%; } }
	.lg-pager {
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 50%;
		box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
		display: block;
		height: 12px;
		-webkit-transition: box-shadow .3s ease 0s;
		-o-transition: box-shadow .3s ease 0s;
		transition: box-shadow .3s ease 0s;
		width: 12px;
		&:focus, &:hover {
			box-shadow: 0 0 0 8px #fff inset; } }
	.lg-caret {
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px dashed;
		bottom: -10px;
		display: inline-block;
		height: 0;
		left: 50%;
		margin-left: -5px;
		position: absolute;
		vertical-align: middle;
		width: 0; }
	width: 100%;
	height: 100%;
	.lg, .lg-inner {
		width: 100%;
		height: 100%; } }

.lg-fullscreen:after {
	content: "\e20c"; }

.lg-fullscreen-on .lg-fullscreen:after {
	content: "\e20d"; }

.group {
	&:after, &:before {
		display: table;
		content: "";
		line-height: 0; }
	&:after {
		clear: both; } }

.lg-outer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	opacity: 0;
	-webkit-transition: opacity .15s ease 0s;
	-o-transition: opacity .15s ease 0s;
	transition: opacity .15s ease 0s;
	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; }
	&.lg-visible {
		opacity: 1; }
	&.lg-css3 {
		.lg-item {
			&.lg-current, &.lg-next-slide, &.lg-prev-slide {
				-webkit-transition-duration: inherit !important;
				transition-duration: inherit !important;
				-webkit-transition-timing-function: inherit !important;
				transition-timing-function: inherit !important; } }
		&.lg-dragging .lg-item {
			&.lg-current, &.lg-next-slide, &.lg-prev-slide {
				-webkit-transition-duration: 0s !important;
				transition-duration: 0s !important;
				opacity: 1; } } }
	&.lg-grab img.lg-object {
		cursor: -webkit-grab;
		cursor: -moz-grab;
		cursor: -o-grab;
		cursor: -ms-grab;
		cursor: grab; }
	&.lg-grabbing img.lg-object {
		cursor: move;
		cursor: -webkit-grabbing;
		cursor: -moz-grabbing;
		cursor: -o-grabbing;
		cursor: -ms-grabbing;
		cursor: grabbing; }
	.lg {
		position: relative;
		overflow: hidden;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		max-height: 100%; }
	.lg-inner {
		position: absolute;
		left: 0;
		top: 0;
		white-space: nowrap; }
	.lg-item {
		background: url(../img/loading.gif) center center no-repeat;
		display: none !important; }
	&.lg-css .lg-current {
		display: inline-block !important; }
	&.lg-css3 {
		.lg-current, .lg-next-slide, .lg-prev-slide {
			display: inline-block !important; } }
	.lg-img-wrap, .lg-item {
		display: inline-block;
		text-align: center;
		position: absolute;
		width: 100%;
		height: 100%; }
	.lg-img-wrap:before, .lg-item:before {
		content: "";
		display: inline-block;
		height: 50%;
		width: 1px;
		margin-right: -1px; }
	.lg-img-wrap {
		position: absolute;
		padding: 0 5px;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0; }
	.lg-item {
		&.lg-complete {
			background-image: none; }
		&.lg-current {
			z-index: 1060; } }
	.lg-image {
		display: inline-block;
		vertical-align: middle;
		max-width: 100%;
		max-height: 100%;
		width: auto !important;
		height: auto !important; }
	&.lg-show-after-load .lg-item {
		.lg-object, .lg-video-play {
			opacity: 0;
			-webkit-transition: opacity .15s ease 0s;
			-o-transition: opacity .15s ease 0s;
			transition: opacity .15s ease 0s; }
		&.lg-complete {
			.lg-object, .lg-video-play {
				opacity: 1; } } }
	.lg-empty-html, &.lg-hide-download #lg-download {
		display: none; } }

.lg-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1040;
	background-color: #000;
	opacity: 0;
	-webkit-transition: opacity .15s ease 0s;
	-o-transition: opacity .15s ease 0s;
	transition: opacity .15s ease 0s;
	&.in {
		opacity: 1; } }

.lg-css3 {
	&.lg-no-trans {
		.lg-current, .lg-next-slide, .lg-prev-slide {
			-webkit-transition: none 0s ease 0s !important;
			-moz-transition: none 0s ease 0s !important;
			-o-transition: none 0s ease 0s !important;
			transition: none 0s ease 0s !important; } }
	&.lg-use-css3 .lg-item, &.lg-use-left .lg-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		backface-visibility: hidden; }
	&.lg-fade .lg-item {
		opacity: 0;
		&.lg-current {
			opacity: 1;
			-webkit-transition: opacity .1s ease 0s;
			-moz-transition: opacity .1s ease 0s;
			-o-transition: opacity .1s ease 0s;
			transition: opacity .1s ease 0s; }
		&.lg-next-slide, &.lg-prev-slide {
			-webkit-transition: opacity .1s ease 0s;
			-moz-transition: opacity .1s ease 0s;
			-o-transition: opacity .1s ease 0s;
			transition: opacity .1s ease 0s; } }
	&.lg-slide {
		&.lg-use-css3 .lg-item {
			opacity: 0;
			&.lg-prev-slide {
				-webkit-transform: translate3d(-100%, 0, 0);
				transform: translate3d(-100%, 0, 0); }
			&.lg-next-slide {
				-webkit-transform: translate3d(100%, 0, 0);
				transform: translate3d(100%, 0, 0); }
			&.lg-current {
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
				opacity: 1;
				-webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }
			&.lg-next-slide, &.lg-prev-slide {
				-webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; } }
		&.lg-use-left .lg-item {
			opacity: 0;
			position: absolute;
			left: 0;
			&.lg-prev-slide {
				left: -100%; }
			&.lg-next-slide {
				left: 100%; }
			&.lg-current {
				left: 0;
				opacity: 1;
				-webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }
			&.lg-next-slide, &.lg-prev-slide {
				-webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; } } } }
