.searchfield {
	opacity: 0;
	position: absolute;
	right: -10px;
	bottom: -60px;
	z-index: 4;
	max-height: 0;
	transform: translateY(20px);
	overflow: hidden;
	text-align: left;
	background: #fff;
	filter: drop-shadow(1px 1px 2px rgba(0,0,0,.5));
	transition: opacity .2s, transform .2s, max-height .2s step-end, padding .2s step-end;
	.field {
		margin-bottom: 0; }
	&.open {
		opacity: 1;
		max-height: 999px;
		padding: 0;
		transform: translateY(0px);
		overflow: visible;
		transition-timing-function: linear, linear, step-start, step-start; }

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: -14px;
		right: 10px;
		width: 0;
		height: 0;
		border: 7px solid transparent;
		border-bottom-color: #fff; }

	form {
		input {
			display: block;
			margin-bottom: 0;
			padding: 7px 17px;
			width: 200px;
			color: #666;
			border: none;
			border-radius: 0;
			box-shadow: none;
			&:focus {
				outline: none;
				box-shadow: none; } }
		button {
			display: none; } } }
.mm-list {
	.searchicon {
		display: none; } }


//.searchoverlay
//isplay: none
//osition: fixed
//op: 0
//ight: 0
//ottom: 0
//eft: 0
//-index: 4
//ext-align: center
//ackground: $searchbackground
//wrapper
//padding-top: 10%
//ccm-search-block-form
//opacity: 0
//position: relative
//top: -100px
//padding-top: 20%
//transition: all 0.4s
//.opensearch &
//	opacity: 1
//	top: 0
//	animation: fadedown .4s
//nput
//display: block
//margin-bottom: 28px
//border: none
//padding: 9px 13px
//width: 100%
//border-radius: 6px
//font-size: 15px
//&[type="submit"]
//	@extend %button
//	width: auto
//3
//margin-bottom: 40px
//font-size: 32px
//font-weight: 700
//color: #f8f8f8
//searchResult
//display: none
//closesearch
//float: right
//font-size: 24px
//color: #ffffff
//transition: all .4s
//transform-origin: center center
//&:hover
//	transform: rotate(90deg)



.content .ccm-search-block-form {
	input {
		display: block;
		margin-bottom: 10px;
		border: none;
		padding: 9px 13px;
		width: 100%;
		border-radius: 6px;
		font-size: 15px;
		background: #fdfdfd;
		box-shadow: inset 0px 0px 1px 0px rgba(0,0,0,0.50);
		&[type*="submit"] {
			@extend %button;
			width: auto; } }
	h3 {
		margin-bottom: 10px;
		font-weight: 700; }
	#searchResults {
		margin-top: 30px; }
	.searchResult {
		h3 {
			font-size: 20px; } } }

