body {
	padding-top: 110px; }
.header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
	padding: 20px 0;
	height: 110px;
	background: #ffffff;
	box-shadow: 0px 1px 1px rgba(0,0,0,.2);
	.logo a {
		display: inline-block;
		margin-top: 10px; }
	.nav {
		> ul {
			padding-top: 17px;
			text-align: right;
			> li {
				display: inline-block;
				margin-left: 20px;
				&.first {
					margin-left: 0; }
				a {
					font-size: 12px;
					text-transform: uppercase;
					letter-spacing: 1px;
					font-weight: bold;
					color: $primary-color;
					&.current {} }
				&.last {
					a {
						display: inline-block;
						border-radius: 4px;
						padding: .6em 2em;
						color: #ffffff;
						background: $secondary-color;
						transition: all .2s;
						&:hover {
							background: darken($secondary-color, 10%); } } }
				ul {
					display: none; } } } } }
//Article header
.article .hero {
	position: relative;
	padding: 150px 0;
	height: 500px;
	//background: url('img/hero.jpg') no-repeat center center
	//background-size: cover
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $overlay-color; }
	.wrapper {
		position: relative;
		z-index: 1; }
	.headerIntro {
		font-size: 25px;
		font-weight: 400;
		color: #e1e1e1; } }









//Mobile navigation
//mobileNav
//display: none
//position: fixed
//top: 0
//right: 0
//left: 0
//z-index: 5
//padding: 5px 20px
//font-size: 20px
//text-align: right
//color: #ffffff
//background: rgba($primary-color, .8)
//.mm-opened &
//	pointer-events: none
//a
//	position: relative
//	&:before
//		content: ''
//		display: block
//		position: absolute
//		top: 0
//		right: 0
//		width: 40px
//		height: 40px
//@media not all and (min-width: map-get($breakpoints, l))
//	display: block
