 :root {
	font-size: 16px; }

//TinyMCE styles
body.mceContentBody {
	font-size: 13px; }


//Fonts
body {
	font-size: 16px;
	line-height: 1.6;
	font-family: $body-font;
	letter-spacing: -.1;
	font-weight: 300;
	color: $body-color;
	-webkit-font-smoothing: antialiased; }

h1,h2,h3,h4,h5,h6 {
	margin: 0;
	font-family: $heading-font;
	font-weight: 400;
	line-height: 1.2; }

h1 {
	margin-bottom: 10px;
	font-size: 55px;
	font-weight: bold;
	color: #333333;
	.hero & {
		color: #ffffff; } }
h2 {
	margin-bottom: 30px;
	font-size: 35px;
	font-weight: 700;
	color: $heading-color;
	letter-spacing: -.5px;
	main:first-child & {
		position: relative;
		margin-bottom: 30px;
		padding-bottom: 45px;
		font-size: 25px;
		font-weight: 400;
		letter-spacing: -.2px;
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 62px;
			height: 7px;
			background: $secondary-color; } } }

h3 {
	margin-top: 10px;
	margin-bottom: 25px;
	font-weight: 700;
	font-size: 16px;
	text-transform: uppercase;
	color: $primary-color; }

h4, h5, h6 {
	margin: 0;
	font-size: 18px;
	font-family: $body-font;
	font-style: italic; }


a {
	color: inherit;
	text-decoration: none; }

p, ul {
	margin: 0 0 30px 0;
	padding-left: 0;
	list-style: none;
	&:last-child {
		margin-bottom: 0; }
	a {
		color: $secondary-color;
		transition: color .2s;
		text-decoration: none;
		&:hover {
			color: lighten($secondary-color, 20%); } }
	.typography &, .mceContentBody & {
		li {
			position: relative;
			margin-bottom: 5px;
			&:before {
				content: '-';
				display: inline-block;
				margin-right: 5px; } } }

	+ h2 {
		margin-top: 30px; }
	+ h3 {
		margin-top: 40px; } }

p.trumpet {
	font-weight: 300;
	font-size: 23px;
	color: lighten(desaturate(adjust-hue($primary-color, 1), 8.97), 37.06); }

a.button {
	@extend %button; }

