//Placeholders
%button {
	display: inline-block;
	border: none;
	padding: 22px 55px;
	min-width: 240px;
	font-family: $body-font;
	font-size: 12px;
	line-height: 1;
	font-weight: 700;
	text-align: center;
	color: #fff;
	background: $secondary-color;
	letter-spacing: 1px;
	border-radius: 0.5em;
	text-transform: uppercase;
	transition: all .2s;
	cursor: pointer;
	&:hover {
		color: #fff;
		background: darken($secondary-color, 10%); }
	&:focus {
		outline: none; }
	&:active {
		transition: all .1s;
		background: darken($secondary-color, 20%); }
	&.small {
		padding: 20px;
		font-size: 9px;
		&.full {
			width: 100%; } } }
