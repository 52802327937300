html.has-notification {
	margin-top: 36px; }
.notificationBar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 50;
	padding: 8px 0;
	text-align: center;
	background: $secondary-color;
	color: #ffffff;
	.wrapper {
		position: relative; }
	p {
		display: block;
		margin: 0;
		width: 100%;
		font-family: sans-serif;
		font-size: 13px;
		float: left; }
	a {
		color: $primary-color; }
	i {
		display: block;
		position: absolute;
		right: 0;
		top: 2px;
		cursor: pointer; } }
