.login-form {
	margin: 150px auto;
	padding: 25px;
	width: 350px;
	max-width: 100%;
	border-radius: 4px;
	box-shadow: 0 0 2px rgba(0,0,0,.1);
	background: #ffffff;
	font-family: sans-serif;
	img {
		display: block;
		margin: 0 auto 20px; }
	.field {
		@extend .s12;
		input {
			border: 1px solid #D5D5D5;
			border-radius: 4px;
			background: #ffffff; }
		label {
			color: #333; }
		&.checkbox {
			input {
				margin: 0 5px 0 0; }
			label, input {
				display: inline-block;
				vertical-align: middle;
				width: auto; } } }
	input.action {
		display: block;
		margin-bottom: 10px;
		border: none;
		padding: 0 15px;
		width: 100%;
		height: 40px;
		line-height: 40px;
		border-radius: 4px;
		color: #ffffff;
		background: #333;
		cursor: pointer;
		&:hover {
			background: linear-gradient(to bottom, #264357 10%, #132430 100%); } }
	#ForgotPassword {}
	a {
		color: #333; } }
