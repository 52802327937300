/*
 *	jQuery.mmenu CSS */
/*
 *	jQuery.mmenu panels CSS */

.mm-menu {
	&.mm-horizontal > .mm-panel {
		-webkit-transition: -webkit-transform 0.4s ease;
		transition: transform 0.4s ease; }

	.mm-hidden {
		display: none; } }

.mm-wrapper {
	overflow-x: hidden;
	position: relative; }

.mm-menu {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;

	> .mm-panel {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		background: inherit;
		-webkit-overflow-scrolling: touch;
		overflow: scroll;
		overflow-x: hidden;
		overflow-y: auto;
		box-sizing: border-box;
		padding: 20px;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);

		&.mm-opened {
			-webkit-transform: translateX(0%);
			transform: translateX(0%); }

		&.mm-subopened {
			-webkit-transform: translateX(-30%);
			transform: translateX(-30%); }

		&.mm-highest {
			z-index: 1; } }

	background: inherit;
	display: block;
	overflow: hidden;
	padding: 0;

	.mm-list {
		padding: 20px 0; }

	> .mm-list {
		padding-bottom: 0;

		&:after {
			content: '';
			display: block;
			height: 40px; } } }

.mm-panel > .mm-list {
	margin-left: -20px;
	margin-right: -20px;

	&:first-child {
		padding-top: 0; } }

.mm-list {
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;

	> li {
		list-style: none;
		display: block;
		padding: 0;
		margin: 0; }

	font: inherit;
	font-size: 14px;

	a {
		text-decoration: none;

		&:hover {
			text-decoration: none; }

		&.mm-subopen {
			background: rgba(3, 2, 1, 0);
			width: 40px;
			height: 100%;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 2;

			&:before {
				content: '';
				border-left-width: 1px;
				border-left-style: solid;
				display: block;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0; }

			&.mm-fullsubopen {
				width: 100%;

				&:before {
					border-left: none; } }

			+ a, + span {
					padding-right: 5px;
					margin-right: 40px; } }

		&.mm-subclose {
			text-indent: 20px;
			padding-top: 30px;
			margin-top: -20px; }

		&.mm-subopen:after, &.mm-subclose:before {
			content: '';
			border: 2px solid transparent;
			display: inline-block;
			width: 7px;
			height: 7px;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
			margin-bottom: -5px;
			position: absolute;
			bottom: 50%; }

		&.mm-subopen:after {
			border-top: none;
			border-left: none;
			right: 18px; }

		&.mm-subclose:before {
			border-right: none;
			border-bottom: none;
			margin-bottom: -15px;
			left: 22px; } }

	> li {
		position: relative;

		> {}
		a, span {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			color: inherit;
			line-height: 20px;
			display: block;
			padding: 10px 10px 10px 20px;
			margin: 0; }

		&:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
			content: '';
			border-bottom-width: 1px;
			border-bottom-style: solid;
			display: block;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			width: auto;
			margin-left: 20px;
			position: relative;
			left: auto; } }

	> li.mm-selected > a {
		&.mm-subopen {
			background: transparent; }

		&.mm-fullsubopen + {}
		a, span {
			padding-right: 45px;
			margin-right: 0; } }

	> li {
		&.mm-label {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			font-size: 10px;
			text-transform: uppercase;
			text-indent: 20px;
			line-height: 25px;
			padding-right: 5px; }

		&.mm-spacer {
			padding-top: 40px;

			&.mm-label {
				padding-top: 25px; } } } }

.mm-menu.mm-vertical .mm-list {
	.mm-panel {
		display: none;
		padding: 10px 0 10px 10px;

		li:last-child:after {
			border-color: transparent; } }

	li.mm-opened > .mm-panel {
		display: block; }

	> li {
		> a.mm-subopen {
			height: 40px;

			&:after {
				top: 16px;
				right: 16px;
				bottom: auto; } }

		&.mm-opened > a.mm-subopen:after {
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg); }

		&.mm-label > a.mm-subopen {
			height: 25px; } } }

html.mm-opened .mm-page {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-menu {
	background: #333333;
	color: rgba(255, 255, 255, 0.6);

	.mm-list > li {
		&:after {
			border-color: rgba(0, 0, 0, 0.15); }

		> a {
			&.mm-subclose {
				background: rgba(0, 0, 0, 0.1);
				color: rgba(255, 255, 255, 0.3); }

			&.mm-subopen:after, &.mm-subclose:before {
				border-color: rgba(255, 255, 255, 0.3); }

			&.mm-subopen:before {
				border-color: rgba(0, 0, 0, 0.15); } }

		&.mm-selected > {}
		a:not(.mm-subopen), span {
			background: rgba(0, 0, 0, 0.1); }

		&.mm-label {
			background: rgba(255, 255, 255, 0.05); } }

	&.mm-vertical .mm-list li.mm-opened > {}
	a.mm-subopen, ul {
		background: rgba(255, 255, 255, 0.05); } }

/*
 *	jQuery.mmenu offcanvas addon CSS */

.mm-page {
	-webkit-transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease; }

html {
	&.mm-opened {
		overflow: hidden;
		position: relative;

		body {
			overflow: hidden; }

		.mm-page {
			box-sizing: border-box;
			position: relative; } }

	&.mm-background .mm-page {
		background: inherit; } }

#mm-blocker {
	background: rgba(3, 2, 1, 0);
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999; }

html {
	&.mm-opened #mm-blocker, &.mm-blocking #mm-blocker {
		display: block; } }

.mm-menu {
	&.mm-offcanvas {
		display: none;
		position: fixed; }

	&.mm-current {
		display: block; }

	width: 80%;
	min-width: 140px;
	max-width: 440px; }

html.mm-opening {
	.mm-page, #mm-blocker {
		-webkit-transform: translate(80%, 0);
		transform: translate(80%, 0); } }

@media all and (max-width: 175px) {
	html.mm-opening {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(140px, 0);
			transform: translate(140px, 0); } } }

@media all and (min-width: 550px) {
	html.mm-opening {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(440px, 0);
			transform: translate(440px, 0); } } }

/*
 *	jQuery.mmenu buttonbars addon CSS */

.mm-buttonbar {
	border: 1px solid transparent;
	border-radius: 5px;
	text-align: center;
	line-height: 20px;
	overflow: hidden;
	display: block;
	padding: 0;
	margin: 0;
	position: relative;

	&:after {
		content: '';
		display: block;
		clear: both; }

	> {}
	* {
		border-left: 1px solid transparent;
		box-sizing: border-box;
		display: block;
		width: 100%;
		height: 100%;
		float: left;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		a {
			text-decoration: none; }

		input {
			position: absolute;
			left: -1000px;
			top: -1000px;

			&:checked + label {
				border-color: transparent  !important; } }

		*:first-child, input:first-child + * {
			border-left: none; } }

	&.mm-buttonbar-2 > * {
		width: 50%; }

	&.mm-buttonbar-3 > * {
		width: 33.33%; }

	&.mm-buttonbar-4 > * {
		width: 25%; }

	&.mm-buttonbar-5 > * {
		width: 20%; } }

.mm-header .mm-buttonbar {
	margin-top: 20px;
	margin-left: -30px;
	margin-right: -30px; }

.mm-footer .mm-buttonbar {
	border: none;
	border-radius: 0;
	line-height: 40px;
	margin: -10px -10px 0 -20px;

	> * {
		border-left: none; } }

.mm-list > li > .mm-buttonbar {
	margin: 10px 20px; }

.mm-menu .mm-buttonbar {
	border-color: rgba(255, 255, 255, 0.6);
	background: #333333;

	> {}
	* {
		border-color: rgba(255, 255, 255, 0.6);

		input:checked + label {
			background: rgba(255, 255, 255, 0.6);
			color: #333333; } } }

/*
 *	jQuery.mmenu counters addon CSS */

em.mm-counter {
	font: inherit;
	font-size: 14px;
	font-style: normal;
	text-indent: 0;
	line-height: 20px;
	display: block;
	margin-top: -10px;
	position: absolute;
	right: 40px;
	top: 50%;

	+ a {
		&.mm-subopen {
			padding-left: 40px;

			+ a, + span {
				margin-right: 80px; } }

		&.mm-fullsubopen {
			padding-left: 0; } } }

.mm-vertical em.mm-counter {
	top: 12px;
	margin-top: 0; }

.mm-nosubresults > em.mm-counter {
	display: none; }

.mm-menu em.mm-counter {
	color: rgba(255, 255, 255, 0.3); }

/*
 *	jQuery.mmenu dragOpen addon CSS */

html.mm-opened.mm-dragging {
	.mm-menu, .mm-page, .mm-fixed-top, .mm-fixed-bottom, #mm-blocker {
		-webkit-transition-duration: 0s;
		transition-duration: 0s; } }

/*
 *	jQuery.mmenu footer addon CSS */

.mm-footer {
	background: inherit;
	border-top: 1px solid transparent;
	text-align: center;
	line-height: 20px;
	box-sizing: border-box;
	width: 100%;
	height: 40px;
	padding: 10px 10px 0 20px;
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0; }

.mm-menu {
	&.mm-hasfooter > .mm-panel:after {
		height: 80px; }

	.mm-footer {
		border-color: rgba(0, 0, 0, 0.15);
		color: rgba(255, 255, 255, 0.3); } }

/*
 *	jQuery.mmenu header addon CSS */

.mm-header {
	background: inherit;
	border-bottom: 1px solid transparent;
	text-align: center;
	line-height: 20px;
	box-sizing: border-box;
	width: 100%;
	height: 60px;
	padding: 0 50px;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;

	.mm-title, .mm-prev, .mm-next {
		padding-top: 30px; }

	.mm-title {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: inline-block;
		width: 100%;
		position: relative; }

	.mm-prev, .mm-next {
		text-decoration: none;
		display: block;
		box-sizing: border-box;
		min-width: 10px;
		height: 100%;
		position: absolute;
		top: 0;
		z-index: 1; }

	.mm-prev {
		padding-left: 20px;
		padding-right: 10px;
		left: 0; }

	.mm-next {
		padding-left: 10px;
		padding-right: 20px;
		right: 0; }

	[href] {
		&.mm-prev:before, &.mm-next:after {
			content: '';
			border: 2px solid transparent;
			display: inline-block;
			width: 7px;
			height: 7px;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg); }

		&.mm-prev:before {
			border-right: none;
			border-bottom: none;
			margin-left: 2px;
			margin-right: 5px; }

		&.mm-next:after {
			border-top: none;
			border-left: none;
			margin-left: 5px;
			margin-right: -2px; } } }

.mm-menu {
	&.mm-hassearch .mm-header {
		height: 50px;
		top: 50px;

		.mm-title, .mm-prev, .mm-next {
			padding-top: 20px; } }

	&.mm-hasheader {
		li.mm-subtitle {
			display: none; }

		> .mm-panel {
			padding-top: 80px;

			&.mm-list {
				padding-top: 60px; }

			> .mm-list:first-child {
				margin-top: -20px; } }

		&.mm-hassearch > .mm-panel {
			padding-top: 120px;

			&.mm-list {
				padding-top: 100px; } } }

	.mm-header {
		border-color: rgba(0, 0, 0, 0.15);
		color: rgba(255, 255, 255, 0.3);

		.mm-prev:before, .mm-next:after {
			border-color: rgba(255, 255, 255, 0.3); } } }

/*
 *	jQuery.mmenu labels addon CSS */

.mm-list > li {
	&.mm-label {
		> span {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			padding: 0;
			line-height: 25px; }

		&.mm-opened a.mm-subopen:after {
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			right: 17px; } }

	&.mm-collapsed {
		display: none; } }

.mm-menu .mm-list li.mm-label > div > div {
	background: rgba(255, 255, 255, 0.05); }

/*
 *	jQuery.mmenu searchfield addon CSS */

.mm-search {
	box-sizing: border-box;

	input {
		box-sizing: border-box; } }

.mm-list > li {
	&.mm-search {
		padding: 10px;
		margin-top: -20px; }

	&.mm-subtitle + li.mm-search {
		margin-top: 0; } }

div.mm-panel > div.mm-search {
	padding: 0 0 10px 0; }

.mm-menu {
	&.mm-hasheader .mm-list > li.mm-search {
		margin-top: 0; }

	> .mm-search {
		background: inherit;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2; } }

.mm-search {
	padding: 10px;

	input {
		border: none;
		border-radius: 30px;
		font: inherit;
		font-size: 14px;
		line-height: 30px;
		outline: none;
		display: block;
		width: 100%;
		height: 30px;
		margin: 0;
		padding: 0 10px;

		&::-ms-clear {
			display: none; } } }

.mm-menu .mm-noresultsmsg {
	text-align: center;
	font-size: 21px;
	display: none;
	padding: 60px 0;

	&:after {
		border: none !important; } }

.mm-noresults .mm-noresultsmsg {
	display: block; }

.mm-menu {
	li.mm-nosubresults > a.mm-subopen {
		display: none;

		+ a, + span {
			padding-right: 10px; } }

	&.mm-hassearch > .mm-panel {
		padding-top: 70px;

		> .mm-list:first-child {
			margin-top: -20px; } }

	&.mm-hasheader > .mm-panel > div.mm-search:first-child {
		margin-top: -10px;

		+ .mm-list {
			padding-top: 0; } }

	.mm-search input {
		background: rgba(255, 255, 255, 0.3);
		color: rgba(255, 255, 255, 0.6); }

	.mm-noresultsmsg {
		color: rgba(255, 255, 255, 0.3); } }

/*
 *	jQuery.mmenu toggles addon CSS */

input {
	&.mm-toggle, &.mm-check {
		position: absolute;
		left: -10000px; } }

label {
	&.mm-toggle, &.mm-check {
		margin: 0;
		position: absolute;
		bottom: 50%;
		z-index: 1; }

	&.mm-toggle:before, &.mm-check:before {
		content: '';
		display: block; }

	&.mm-toggle {
		border-radius: 30px;
		width: 50px;
		height: 30px;
		margin-bottom: -15px;

		&:before {
			border-radius: 30px;
			width: 28px;
			height: 28px;
			margin: 1px; } } }

input.mm-toggle:checked ~ label.mm-toggle:before {
	float: right; }

label.mm-check {
	width: 30px;
	height: 30px;
	margin-bottom: -15px;

	&:before {
		border-left: 3px solid;
		border-bottom: 3px solid;
		width: 40%;
		height: 20%;
		margin: 25% 0 0 20%;
		opacity: 0.1;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg); } }

input.mm-check:checked ~ label.mm-check:before {
	opacity: 1; }

.mm-menu.mm-vertical .mm-list > li label {
	&.mm-toggle, &.mm-check {
		bottom: auto;
		margin-bottom: 0; }

	&.mm-toggle, &.mm-check {
		top: 5px; } }

label {
	&.mm-toggle, &.mm-check {
		right: 20px; }

	&.mm-toggle + {}
	a, span {
		margin-right: 70px; }

	&.mm-check + {}
	a, span {
		margin-right: 50px; } }

a.mm-subopen + label {
	&.mm-toggle, &.mm-check {
		right: 50px; }

	&.mm-toggle + {}
	a, span {
		margin-right: 100px; }

	&.mm-check + {}
	a, span {
		margin-right: 80px; } }

em.mm-counter + a.mm-subopen + label {
	&.mm-toggle, &.mm-check {
		right: 90px; }

	&.mm-toggle + {}
	a, span {
		margin-right: 140px; }

	&.mm-check + {}
	a, span {
		margin-right: 120px; } }

.mm-menu {
	label.mm-toggle {
		background: rgba(0, 0, 0, 0.15);

		&:before {
			background: #333333; } }

	input.mm-toggle:checked ~ label.mm-toggle {
		background: #4bd963; }

	label.mm-check:before {
		border-color: rgba(255, 255, 255, 0.6); } }

/*
 *	jQuery.mmenu effects extension CSS */

html {
	&.mm-slide {
		.mm-menu {
			-webkit-transition: -webkit-transform 0.4s ease;
			transition: transform 0.4s ease; }

		&.mm-opened .mm-menu {
			-webkit-transform: translateX(-30%);
			transform: translateX(-30%); }

		&.mm-opening .mm-menu {
			-webkit-transform: translateX(0%);
			transform: translateX(0%); }

		&.mm-right {
			&.mm-opened .mm-menu {
				-webkit-transform: translateX(30%);
				transform: translateX(30%); }

			&.mm-opening .mm-menu {
				-webkit-transform: translateX(0%);
				transform: translateX(0%); } }

		&.mm-top {
			&.mm-opened .mm-menu {
				-webkit-transform: translateY(-30%);
				transform: translateY(-30%); }

			&.mm-opening .mm-menu {
				-webkit-transform: translateY(0%);
				transform: translateY(0%); } }

		&.mm-bottom {
			&.mm-opened .mm-menu {
				-webkit-transform: translateY(30%);
				transform: translateY(30%); }

			&.mm-opening .mm-menu {
				-webkit-transform: translateY(0%);
				transform: translateY(0%); } } }

	&.mm-zoom-menu {
		.mm-menu {
			-webkit-transition: -webkit-transform 0.4s ease;
			transition: transform 0.4s ease; }

		&.mm-opened .mm-menu {
			-webkit-transform: scale(0.7, 0.7) translateX(-30%);
			transform: scale(0.7, 0.7) translateX(-30%);
			-webkit-transform-origin: left center;
			transform-origin: left center; }

		&.mm-opening .mm-menu {
			-webkit-transform: scale(1, 1) translateX(0%);
			transform: scale(1, 1) translateX(0%); }

		&.mm-right {
			&.mm-opened .mm-menu {
				-webkit-transform: scale(0.7, 0.7) translateX(30%);
				transform: scale(0.7, 0.7) translateX(30%);
				-webkit-transform-origin: right center;
				transform-origin: right center; }

			&.mm-opening .mm-menu {
				-webkit-transform: scale(1, 1) translateX(0%);
				transform: scale(1, 1) translateX(0%); } }

		&.mm-top {
			&.mm-opened .mm-menu {
				-webkit-transform: scale(0.7, 0.7) translateY(-30%);
				transform: scale(0.7, 0.7) translateY(-30%);
				-webkit-transform-origin: center top;
				transform-origin: center top; }

			&.mm-opening .mm-menu {
				-webkit-transform: scale(1, 1) translateY(0%);
				transform: scale(1, 1) translateY(0%); } }

		&.mm-bottom {
			&.mm-opened .mm-menu {
				-webkit-transform: scale(0.7, 0.7) translateY(30%);
				transform: scale(0.7, 0.7) translateY(30%);
				-webkit-transform-origin: center bottom;
				transform-origin: center bottom; }

			&.mm-opening .mm-menu {
				-webkit-transform: scale(1, 1) translateY(0%);
				transform: scale(1, 1) translateY(0%); } } }

	&.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel {
		-webkit-transform: scale(1.5, 1.5) translateX(100%);
		transform: scale(1.5, 1.5) translateX(100%);
		-webkit-transform-origin: left center;
		transform-origin: left center;
		-webkit-transition-property: -webkit-transform, left;
		transition-property: transform, left;

		&.mm-opened {
			-webkit-transform: scale(1, 1) translateX(0%);
			transform: scale(1, 1) translateX(0%);

			&.mm-subopened {
				-webkit-transform: scale(0.7, 0.7) translateX(-30%);
				transform: scale(0.7, 0.7) translateX(-30%); } } } }

/*
 *	jQuery.mmenu fullscreen extension CSS */

.mm-menu.mm-fullscreen {
	width: 100%;
	min-width: 140px;
	max-width: 10000px; }

html {
	&.mm-opening.mm-fullscreen {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(100%, 0);
			transform: translate(100%, 0); } }

	&.mm-right.mm-opening.mm-fullscreen {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(-100%, 0);
			transform: translate(-100%, 0); } } }

@media all and (max-width: 140px) {
	html.mm-opening.mm-fullscreen {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(140px, 0);
			transform: translate(140px, 0); } } }

@media all and (min-width: 10000px) {
	html.mm-opening.mm-fullscreen {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(10000px, 0);
			transform: translate(10000px, 0); } } }

@media all and (max-width: 140px) {
	html.mm-right.mm-opening.mm-fullscreen {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(-140px, 0);
			transform: translate(-140px, 0); } } }

@media all and (min-width: 10000px) {
	html.mm-right.mm-opening.mm-fullscreen {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(-10000px, 0);
			transform: translate(-10000px, 0); } } }

.mm-menu.mm-front.mm-fullscreen {
	&.mm-top, &.mm-bottom {
		height: 100%;
		min-height: 140px;
		max-height: 10000px; } }

html.mm-opened.mm-fullscreen .mm-page {
	box-shadow: none !important; }

/*
 *	jQuery.mmenu position extension CSS */

.mm-menu {
	&.mm-top, &.mm-bottom {
		width: 100%;
		min-width: 100%;
		max-width: 100%; }

	&.mm-right {
		left: auto;
		right: 0; }

	&.mm-bottom {
		top: auto;
		bottom: 0; } }

html {
	&.mm-right.mm-opening {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(-80%, 0);
			transform: translate(-80%, 0); } }

	&.mm-front {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(0, 0) !important;
			transform: translate(0, 0) !important;
			z-index: 0; } } }

@media all and (max-width: 175px) {
	html.mm-right.mm-opening {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(-140px, 0);
			transform: translate(-140px, 0); } } }

@media all and (min-width: 550px) {
	html.mm-right.mm-opening {
		.mm-page, #mm-blocker {
			-webkit-transform: translate(-440px, 0);
			transform: translate(-440px, 0); } } }

/*
 *	jQuery.mmenu z-position extension CSS */

.mm-menu.mm-front {
	z-index: 1;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); }

html.mm-opened.mm-next .mm-page {
	box-shadow: none; }

.mm-menu {
	&.mm-front, &.mm-next {
		-webkit-transition: -webkit-transform 0.4s ease;
		transition: transform 0.4s ease;
		-webkit-transform: translate(-100%, 0);
		transform: translate(-100%, 0); }

	&.mm-front.mm-right, &.mm-next.mm-right {
		-webkit-transform: translate(100%, 0);
		transform: translate(100%, 0); }

	&.mm-front {
		&.mm-top {
			-webkit-transform: translate(0, -100%);
			transform: translate(0, -100%); }

		&.mm-bottom {
			-webkit-transform: translate(0, 100%);
			transform: translate(0, 100%); } } }

html.mm-opening .mm-menu {
	&.mm-front, &.mm-next {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0); } }

.mm-menu.mm-front {
	&.mm-top, &.mm-bottom {
		height: 80%;
		min-height: 140px;
		max-height: 880px; } }

/*
 *	jQuery.mmenu themes extension CSS */

html.mm-opened.mm-light .mm-page {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light {
	background: #f3f3f3;
	color: rgba(0, 0, 0, 0.6);

	.mm-list > li {
		&:after {
			border-color: rgba(0, 0, 0, 0.1); }

		> a {
			&.mm-subclose {
				background: rgba(255, 255, 255, 0.6);
				color: rgba(0, 0, 0, 0.3); }

			&.mm-subopen:after, &.mm-subclose:before {
				border-color: rgba(0, 0, 0, 0.3); }

			&.mm-subopen:before {
				border-color: rgba(0, 0, 0, 0.1); } }

		&.mm-selected > {}
		a:not(.mm-subopen), span {
			background: rgba(255, 255, 255, 0.6); }

		&.mm-label {
			background: rgba(0, 0, 0, 0.03); } }

	&.mm-vertical .mm-list li.mm-opened > {}
	a.mm-subopen, ul {
		background: rgba(0, 0, 0, 0.03); }

	.mm-buttonbar {
		border-color: rgba(0, 0, 0, 0.6);
		background: #f3f3f3;

		> {}
		* {
			border-color: rgba(0, 0, 0, 0.6);

			input:checked + label {
				background: rgba(0, 0, 0, 0.6);
				color: #f3f3f3; } } }

	label.mm-check:before {
		border-color: rgba(0, 0, 0, 0.6); }

	em.mm-counter {
		color: rgba(0, 0, 0, 0.3); }

	.mm-footer {
		border-color: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.3); }

	.mm-header {
		border-color: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.3);

		.mm-prev:before, .mm-next:after {
			border-color: rgba(0, 0, 0, 0.3); } }

	.mm-list li.mm-label > div > div {
		background: rgba(0, 0, 0, 0.03); }

	.mm-search input {
		background: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.6); }

	.mm-noresultsmsg {
		color: rgba(0, 0, 0, 0.3); }

	label.mm-toggle {
		background: rgba(0, 0, 0, 0.1);

		&:before {
			background: #f3f3f3; } }

	input.mm-toggle:checked ~ label.mm-toggle {
		background: #4bd963; } }

html.mm-opened.mm-white .mm-page {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white {
	background: white;
	color: rgba(0, 0, 0, 0.6);

	.mm-list > li {
		&:after {
			border-color: rgba(0, 0, 0, 0.1); }

		> a {
			&.mm-subclose {
				background: rgba(0, 0, 0, 0.06);
				color: rgba(0, 0, 0, 0.3); }

			&.mm-subopen:after, &.mm-subclose:before {
				border-color: rgba(0, 0, 0, 0.3); }

			&.mm-subopen:before {
				border-color: rgba(0, 0, 0, 0.1); } }

		&.mm-selected > {}
		a:not(.mm-subopen), span {
			background: rgba(0, 0, 0, 0.06); }

		&.mm-label {
			background: rgba(0, 0, 0, 0.03); } }

	&.mm-vertical .mm-list li.mm-opened > {}
	a.mm-subopen, ul {
		background: rgba(0, 0, 0, 0.03); }

	.mm-buttonbar {
		border-color: rgba(0, 0, 0, 0.6);
		background: white;

		> {}
		* {
			border-color: rgba(0, 0, 0, 0.6);

			input:checked + label {
				background: rgba(0, 0, 0, 0.6);
				color: white; } } }

	label.mm-check:before {
		border-color: rgba(0, 0, 0, 0.6); }

	em.mm-counter {
		color: rgba(0, 0, 0, 0.3); }

	.mm-footer {
		border-color: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.3); }

	.mm-header {
		border-color: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.3);

		.mm-prev:before, .mm-next:after {
			border-color: rgba(0, 0, 0, 0.3); } }

	.mm-list li.mm-label > div > div {
		background: rgba(0, 0, 0, 0.03); }

	.mm-search input {
		background: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.6); }

	.mm-noresultsmsg {
		color: rgba(0, 0, 0, 0.3); }

	label.mm-toggle {
		background: rgba(0, 0, 0, 0.1);

		&:before {
			background: white; } }

	input.mm-toggle:checked ~ label.mm-toggle {
		background: #4bd963; } }

html.mm-opened.mm-black .mm-page {
	box-shadow: none; }

.mm-menu.mm-black {
	background: black;
	color: rgba(255, 255, 255, 0.6);

	.mm-list > li {
		&:after {
			border-color: rgba(255, 255, 255, 0.2); }

		> a {
			&.mm-subclose {
				background: rgba(255, 255, 255, 0.25);
				color: rgba(255, 255, 255, 0.3); }

			&.mm-subopen:after, &.mm-subclose:before {
				border-color: rgba(255, 255, 255, 0.3); }

			&.mm-subopen:before {
				border-color: rgba(255, 255, 255, 0.2); } }

		&.mm-selected > {}
		a:not(.mm-subopen), span {
			background: rgba(255, 255, 255, 0.25); }

		&.mm-label {
			background: rgba(255, 255, 255, 0.15); } }

	&.mm-vertical .mm-list li.mm-opened > {}
	a.mm-subopen, ul {
		background: rgba(255, 255, 255, 0.15); }

	.mm-buttonbar {
		border-color: rgba(255, 255, 255, 0.6);
		background: black;

		> {}
		* {
			border-color: rgba(255, 255, 255, 0.6);

			input:checked + label {
				background: rgba(255, 255, 255, 0.6);
				color: black; } } }

	label.mm-check:before {
		border-color: rgba(255, 255, 255, 0.6); }

	em.mm-counter {
		color: rgba(255, 255, 255, 0.3); }

	.mm-footer {
		border-color: rgba(255, 255, 255, 0.2);
		color: rgba(255, 255, 255, 0.3); }

	.mm-header {
		border-color: rgba(255, 255, 255, 0.2);
		color: rgba(255, 255, 255, 0.3);

		.mm-prev:before, .mm-next:after {
			border-color: rgba(255, 255, 255, 0.3); } }

	.mm-list li.mm-label > div > div {
		background: rgba(255, 255, 255, 0.15); }

	.mm-search input {
		background: rgba(255, 255, 255, 0.3);
		color: rgba(255, 255, 255, 0.6); }

	.mm-noresultsmsg {
		color: rgba(255, 255, 255, 0.3); }

	label.mm-toggle {
		background: rgba(255, 255, 255, 0.2);

		&:before {
			background: black; } }

	input.mm-toggle:checked ~ label.mm-toggle {
		background: #4bd963; } }
