body #ui-datepicker-div {
	border: none;
	padding: 0;
	box-shadow: 1px 1px 4px rgba(0,0,0,.4);
	.ui-widget {
		font-family: $body-font; }
	.ui-datepicker {
		padding: 0; }
	.ui-widget-content {
		border: none;
		background: #fff;
		-moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.19);
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.19);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.19); }
	.ui-datepicker-header {
		overflow: hidden;
		border-top: 1px solid darken(desaturate(adjust-hue($datepicker-color, -0.1203), 1.4401), 4.9020);
		border-bottom: none;
		padding: 0;
		height: 2.2em;
		font-family: $body-font;
		color: #fff;
		text-transform: uppercase;
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.35);
		border-radius: 4px 4px 0 0;
		-webkit-border-radius: 4px 4px 0 0;
		-moz-border-radius: 4px 4px 0 0;
		background-image: linear-gradient(to top, darken(desaturate(adjust-hue($datepicker-color, 0.2369), 0.5190), 5.2941) 0%, $datepicker-color 100%);
		.ui-state-hover {
			background: transparent;
			border-color: transparent;
			cursor: pointer; } }
	.ui-datepicker {}
	.ui-datepicker-title {
		font-size: 0.9em;
		line-height: 2.2em;
		font-weight: normal; }
	.ui-datepicker-prev, .ui-datepicker-next {
		top: -1px;
		background: lighten(desaturate(adjust-hue($datepicker-color, 2.2458), 5.4618), 2.5490);
		border-radius: 0;
		font-weight: normal;
		border: none;
		height: 2.2em;
		width: 1.4em; }
	.ui-datepicker-prev.ui-state-hover, .ui-datepicker-next.ui-state-hover {
		background: darken(desaturate(adjust-hue($datepicker-color, -0.1572), 1.0613), 3.7255); }
	.ui-datepicker-prev {
		left: 0;
		border-right: 1px solid darken(desaturate(adjust-hue($datepicker-color, 0.0341), 1.3695), 7.8431); }
	.ui-datepicker-next {
		right: 0;
		border-left: 1px solid darken(desaturate(adjust-hue($datepicker-color, 0.0341), 1.3695), 7.8431); }
	.ui-datepicker-prev span, .ui-datepicker-next span {
		margin-top: -4px;
		top: 40%; }
	.ui-icon-circle-triangle-e {
		background-image: url(img/ui-icons_ffffff_256x240.png);
		background-position: -32px 0; }
	.ui-icon-circle-triangle-w {
		background-image: url(img/ui-icons_ffffff_256x240.png);
		background-position: -96px 0; }
	table {
		margin: 0;
		font-size: 0.8em; }
	th {
		padding: .6em 0 .4em;
		text-transform: uppercase;
		color: #bbb;
		font-family: $body-font;
		font-weight: normal;
		font-size: .8em;
		border-top: 1px solid #e6e6e6; }
	td {
		border: none;
		border-top: 1px solid #e6e6e6;
		border-right: 1px solid #e6e6e6;
		padding: 0; }
	td {
		&:last-child {
			border-right: 0; }
		.ui-state-default {
			background: transparent;
			border: none;
			color: #61553e;
			margin: 0;
			font-family: $body-font;
			font-weight: normal;
			text-align: center; } }
	.ui-datepicker-calendar .ui-state-hover {
		background: #ededed;
		color: #b2b2b2; }
	td a.ui-state-active {
		color: #fff;
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.45);
		background-image: linear-gradient(to top, darken(desaturate(adjust-hue($datepicker-color, 0.2369), 0.5190), 5.2941) 0%, $datepicker-color 100%);
		&.ui-state-hover {
			color: #fff;
			text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.45);
			background-image: linear-gradient(to top, darken(desaturate(adjust-hue($datepicker-color, 0.2369), 0.5190), 5.2941) 0%, $datepicker-color 100%); } } }
