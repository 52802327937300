.wrapper {
	margin: 0 auto;
	width: 1200px;
	max-width: 100%;
	padding: 0 15px; }

//Popup
.popup {
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	transition: all .2s;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: $overlay-color; }
	&.shown {
		opacity: 1;
		visibility: visible;
		.wrapper {
			margin-top: 175px; } }
	.close {
		display: block;
		position: absolute;
		top: 140px;
		right: 25%;
		width: 35px;
		height: 35px;
		background: url('img/close.svg') no-repeat center center;
		background-size: 100% 100%;
		overflow: hidden;
		border-radius: 50%;
		transition: all .2s;
		cursor: pointer;
		&:hover {
			transform: rotate(90deg); } }
	.wrapper {
		transition: all .4s; } }

//Triangles
.poly-grey-triangle {
	position: relative;
	z-index: 1;
	margin-top: calc( 113 / 1600 * -100% ); //Formular for keeping the triangle position above the wrapper
	padding-top: calc( 113 / 1600 * 100% + 65px );
	padding-bottom: 120px;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: url('img/poly-grey-triangle.svg') no-repeat top center transparent;
		background-size: 100% auto; }
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 500px;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -2;
		background: #f8f8f8; } }

.simple-triangle {
	position: relative;
	z-index: 1;
	margin-top: calc( 58 / 1600 * -100% ); //Formular for keeping the triangle position above the wrapper
	padding-top: calc( 58 / 1600 * 100% + 65px );
	padding-bottom: 100px;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: url('img/simple-triangle.svg') no-repeat top center transparent;
		background-size: 100% auto; }
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 500px;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -2; } }

