.ProductPage {
	.packages {
		padding: 80px 0 120px;
		text-align: center;
		.package-list {
			margin-top: 70px; }
		.package-item {
			padding: 0 25px;
			.head {
				margin-bottom: 20px; }
			.icon {
				display: inline-block;
				width: 105px;
				height: 95px; }
			.preHeading {
				@extend h3;
				margin-bottom: 10px;
				line-height: 1; }
			.heading {
				@extend h2;
				margin-bottom: 5px;
				line-height: 1; }
			.subHeading {
				font-size: 11px;
				font-weight: bold;
				color: $secondary-color;
				letter-spacing: 1px; }
			.description {
				margin-bottom: 30px;
				text-align: left; } } } }

