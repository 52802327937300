//  Global styling
*,*:before,*:after {
	margin: 0;
	padding: 0;
	color: inherit;
	text-decoration: none;
	box-sizing: border-box;
	font-family: inherit;
	list-style: none; }

::selection {
	background: $primary-color;
	color: #ffffff; }

img {
	border: none;
	max-width: 100%;
	height: auto; }

a {
	cursor: pointer;
	transition: all .2s; }

html {
	height: 100%; }
html, body {
	min-height: 100%; }
body {
	overflow-y: scroll; }

address {
	font-style: normal; }
