.cookieMessage {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #F1F1F1;
	min-height: 50px;
	padding: 16px 0;
	z-index: 50;
	p {
		display: block;
		margin: 0;
		width: 97%;
		font-family: sans-serif;
		font-size: 13px;
		color: #8C8C8C;
		float: left; }
	a {
		color: $primary-color; }
	i {
		padding-top: 1px;
		display: block;
		width: 3%;
		float: right;
		text-align: right;
		color: #9E9E9E;
		cursor: pointer; } }
