#map {
	height: 385px; }
.map-title {
	.wrapper {
		position: relative; }
	.title {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 1;
		padding: 10px 20px;
		width: 300px;
		max-width: 100%;
		font-size: 20px;
		color: #ffffff;
		background: rgba($primary-color,.85);
		transform: translateY(100%); } }
