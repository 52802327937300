 :-ms-input-placeholder {
	color: #9CA0A6 !important;
	opacity: 1; }

form {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	margin: -5px 0;
	width: 100%;
	> * {
		margin: $form-gutter/2 0;
		width: 100%;
		flex: 0 1 100%; }
	> .field { //Set padding as spacer instead of margin, for field elements
		margin: 0;
		padding: $form-gutter/2 0; }
	> .two {
		flex: 0 1 calc(1/2*100% - (1 - 1/2) * #{$form-gutter}); }
	> .three {
		flex: 0 1 calc(1/3*100% - (1 - 1/3) * #{$form-gutter}); }
	> .four {
		flex: 0 1 calc(1/4*100% - (1 - 1/4) * #{$form-gutter}); }
	&:after {
		content: '';
		display: block;
		clear: both; }
	input,textarea,select {
		display: flex;
		border: 1px solid #CACACA;
		padding: 8px 10px;
		width: 100%;
		font-size: 13px;
		color: #333;
		border-radius: 3px;
		background: #FEFEFE;
		&:focus {
			outline: none;
			background: lighten($primary-color, 70%); }
		&::placeholder {
			opacity: 1;
			color: #9CA0A6 !important; }
		&[type="radio"],&[type="checkbox"] {
			display: inline-block;
			margin-right: 5px;
			width: auto;
			+ label {
				display: inline-block;
				font-size: 14px;
				color: #6c6c6c; } } }
	input[type="submit"],button[type="submit"] {
		@extend %button;
		flex-basis: auto;
		width: auto;
		margin-left: auto;
		margin-top: 10px;
		&.center {
			margin-left: auto;
			margin-right: auto; } }
	select {
		padding-right: 20px;
		appearance: none;
		background-image: url('img/form-select.svg');
		background-repeat: no-repeat;
		background-position: right 15px center;
		&:after {
			content: '';
			display: block;
			border: 5px solid transparent;
			border-top-color: #666; }
		option {
			background: #ffffff; }
		&::-ms-expand {
			display: none; } }
	.message {
		display: block;
		margin-bottom: 5px;
		padding: 5px 10px;
		border-radius: 3px;
		font-weight: bold;
		font-family: sans-serif;
		&.good {
			background: $success-color;
			color: #ffffff; }
		&.bad {
			background: $alert-color;
			color: #ffffff; }
		&.validation, &.required {
			background: $warning-color;
			color: #ffffff; } } }

.learnMoreForm {
	margin: -200px 0 0;
	padding: 40px 50px 60px;
	width: 400px;
	background: #ffffff;
	box-shadow: 3px 3px 12px rgba(0,0,0,.1);
	.heading {
		@extend h2;
		margin-bottom: 18px;
		font-size: 32px;
		text-align: center; }
	.subHeading {
		margin-bottom: 25px;
		text-align: center;
		font-size: 13px; }
	[type="submit"] {
		margin-top: 24px;
		width: 100%; } }

.popup-form {
	font-size: 15px;
	font-weight: 300;
	color: #f0f0f0;
	text-align: center;
	.wrapper {
		width: 575px;
		max-width: 100%; }
	.heading {
		margin-bottom: 10px;
		font-size: 50px;
		font-weight: bold;
		color: #ffffff; }
	[type="submit"] {
		margin-top: 35px; }
	form {
		margin-top: 20px; } }

.footerForm {
	position: relative;
	z-index: 1;
	margin: -710px auto 0;
	padding: 45px 125px;
	width: 930px;
	max-width: 100%;
	text-align: center;
	background: #ffffff;
	box-shadow: 3px 3px 12px rgba(0,0,0,.15);
	.title {
		@extend h2; }
	.description {
		margin-bottom: 30px;
		font-weight: 300;
		line-height: 1.8;
		font-size: 13px;
		color: $body-color; } }





