.footer {
	position: relative;
	margin-top: 185px;
	color: #C5C5C5;
	background: darken($primary-color, 9.61);
	.footerImages {
		display: block;
		margin-bottom: 250px;
		width: 100%;
		height: 275px;
		background: url('img/footerImages.jpg') no-repeat center center;
		@media (min-width: 1876px) {
			padding-bottom: 14.6%;
			height: 0;
			background-size: 100% auto; } }
	.footerText {
		margin-top: 80px;
		padding-bottom: 130px;
		.heading {
			margin-bottom: 21px;
			font-size: 15px;
			font-weight: 400;
			color: #9ca0a6;
			text-transform: uppercase; }
		p {
			font-size: 13px;
			font-weight: 300;
			color: #f0f0f0;
			line-height: 1.8; } }
	.logo {
		&:after {
			content: '';
			display: block;
			margin-top: 30px;
			width: 30px;
			height: 2px;
			background: #ffffff; }
		svg path {
			fill: #fff; }
		@media (min-width: map-get($breakpoints, m)) {
			float: right; } }
	.address {
		border-top: 2px solid rgba(#fff,.05);
		padding: 25px 0 75px;
		font-size: 9px;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-weight: bold;
		color: rgba(#fff, .3);
		a {
			color: inherit;
			&:hover {
				color: #fff; } }
		li {
			display: inline-block;
			vertical-align: middle; }
		.social {
			font-size: 13px;
			margin-right: 5px; }
		.companyinfo {
			margin-left: 10px; }
		.phone {
			color: rgba(#fff, .6); }
		.footerLinks {
			color: rgba(#fff, .6);
			text-align: right;
			li {
				margin-left: 12px; }
			.aaa {
				opacity: .4; } } } }
